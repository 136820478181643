import React from "react";

const StoreIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 0.75C1.08579 0.75 0.75 1.08579 0.75 1.5C0.75 1.91421 1.08579 2.25 1.5 2.25V0.75ZM3.16667 1.5L3.91446 1.44248C3.8844 1.05173 3.55857 0.75 3.16667 0.75V1.5ZM16.5 4L17.2434 4.09912C17.272 3.88482 17.2067 3.66862 17.0643 3.50596C16.9219 3.34329 16.7162 3.25 16.5 3.25V4ZM3.35897 4L2.61118 4.05752L3.35897 4ZM14.1476 11.598L14.0934 10.85L14.1476 11.598ZM15.6792 10.156L16.4226 10.2551L15.6792 10.156ZM5.65495 12.2134L5.60075 11.4654L5.65495 12.2134ZM1.5 2.25H3.16667V0.75H1.5V2.25ZM5.70916 12.9614L14.2018 12.346L14.0934 10.85L5.60075 11.4654L5.70916 12.9614ZM16.4226 10.2551L17.2434 4.09912L15.7566 3.90088L14.9358 10.0568L16.4226 10.2551ZM2.41888 1.55752L2.61118 4.05752L4.10677 3.94248L3.91446 1.44248L2.41888 1.55752ZM2.61118 4.05752L3.12495 10.7365L4.62053 10.6214L4.10677 3.94248L2.61118 4.05752ZM16.5 3.25H3.35897V4.75H16.5V3.25ZM14.2018 12.346C15.344 12.2633 16.2713 11.3902 16.4226 10.2551L14.9358 10.0568C14.8784 10.4874 14.5267 10.8186 14.0934 10.85L14.2018 12.346ZM5.60075 11.4654C5.09739 11.5018 4.65924 11.1246 4.62053 10.6214L3.12495 10.7365C3.22699 12.063 4.38213 13.0576 5.70916 12.9614L5.60075 11.4654Z"
      fill="#0071EB"
    />
    <rect
      x="6.91663"
      y="16.084"
      width="0.00875"
      height="0.00875"
      stroke="#0071EB"
      strokeWidth="3.33333"
      strokeLinejoin="round"
    />
    <rect
      x="13.5834"
      y="16.084"
      width="0.00875"
      height="0.00875"
      stroke="#0071EB"
      strokeWidth="3.33333"
      strokeLinejoin="round"
    />
  </svg>
);

export default StoreIcon;
