import { useEffect } from "react";
import {
  themes as appTheme,
  applyTheme,
} from "@mecha/design-components/dist/src/styles/theme";
import { useTheme } from "next-themes";
import { isWindowUndefined } from "./isWindowUndefined";

function useToggleTheme() {
  const { theme, setTheme } = useTheme();
  useEffect(() => {
    if (!isWindowUndefined(window)) {
      const root = window.document.documentElement;
      if (theme === "light") {
        localStorage.setItem("theme", "light");
        root.classList.remove("dark");
        root.classList.add("light");
        applyTheme(appTheme.default);
      } else {
        localStorage.setItem("theme", "dark");
        root.classList.remove("light");
        root.classList.add("dark");
        applyTheme(appTheme.dark);
      }
    }
  }, [theme]);
  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };
  return toggleTheme;
}

export default useToggleTheme;
