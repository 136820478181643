import React from "react";

const LogoutIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.66634 10L12.333 6.99996M12.333 6.99996L9.66634 4M12.333 6.99996L4.33301 6.99996"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66699 1.66797L2.33366 1.66797C1.59728 1.66797 1.00033 2.26492 1.00033 3.0013L1.00033 11.0013C1.00033 11.7377 1.59728 12.3346 2.33366 12.3346L5.66699 12.3346"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LogoutIcon;
