import React from "react";

const SettingsIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="20.5001"
      cy="20"
      r="3.33333"
      stroke="currentColor"
      strokeWidth={props.strokeWidth || "3"}
    />
    <path
      d="M9.49744 9.79869C7.94788 11.4688 6.82268 13.4509 6.16752 15.5743L8.83303 17.1133C11.0553 18.3963 11.0553 21.6038 8.83303 22.8868L6.16467 24.4274C6.49002 25.4774 6.93662 26.5078 7.50946 27.5C8.08227 28.4922 8.75132 29.3941 9.49799 30.2009L12.1664 28.6603C14.3886 27.3773 17.1664 28.981 17.1664 31.547L17.1664 34.625C19.333 35.1193 21.6121 35.1359 23.8332 34.629L23.8332 31.5471C23.8332 28.9811 26.611 27.3774 28.8332 28.6604L31.5022 30.2013C33.0518 28.5312 34.177 26.5492 34.8322 24.4257L32.1665 22.8867C29.9443 21.6037 29.9443 18.3962 32.1665 17.1132L34.835 15.5726C34.5096 14.5225 34.063 13.4922 33.4902 12.5C32.9174 11.5078 32.2483 10.6059 31.5016 9.79907L28.8332 11.3397C26.611 12.6227 23.8332 11.0189 23.8332 8.45291L23.8332 5.37502C21.6667 4.88068 19.3876 4.86412 17.1664 5.371L17.1664 8.45287C17.1664 11.0189 14.3886 12.6226 12.1664 11.3396L9.49744 9.79869Z"
      stroke="currentColor"
      strokeWidth={props.strokeWidth || "3"}
      strokeLinejoin="round"
    />
  </svg>
);

export default SettingsIcon;
