import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import Link from "next/link";
import Layout from "../components/Layout/Layout";

interface IErrorPage {
  title: string;
  error: string;
  description?: string;
  redirectToauth: boolean;
}
const ErrorPage = ({
  title,
  error,
  description,
  redirectToauth,
}: IErrorPage) => {
  return (
    <Layout
      title="Error - Mecha"
      headerTitle={title}
      backgroundImageUrl="/assets/error-banner.png"
      isErrorPage
    >
      <div className="flex flex-col items-center">
        <p className="text-web-b4 dark:text-dark-n-500 text-light-n-500 font-mediumm text-center">
          {error}
        </p>
        {description && (
          <div className="mt-5 border-2 rounded-lg dark:border-dark-r-500 border-light-r-500 py-[14px] px-2 dark:bg-dark-r-520 bg-light-r-500/20">
            <p className="dark:text-dark-n-300 text-light-n-300 text-web-b4 text-center">
              {description}
            </p>
          </div>
        )}
        <p className="mt-10 text-web-b4 dark:text-dark-n-500 text-light-n-500 font-mediumm text-center">
          If you need any help, reach out to us at 
          <a
            href="mailto:help@mecha.so"
            className="dark:text-dark-b-500 text-light-b-500"
          >
            help@mecha.so
          </a>
        </p>
        {redirectToauth ? (
          <Link
            href={"/auth/signin"}
            className="__link-back-to-login dark:text-dark-n-200 text-ligth-n-200 font-medium flex flex-row gap-1 items-center mt-12"
          >
            <ArrowLeftIcon
              height={18}
              width={18}
              className="text-neutral-110 dark:text-storm-dust-100 __navigate-signin"
            />
            <p>Back to Sign in</p>
          </Link>
        ) : (
          <Link
            href={"/"}
            className="__link-back-to-login dark:text-dark-n-200 text-ligth-n-200 font-medium flex flex-row gap-1 items-center mt-12"
          >
            <ArrowLeftIcon
              height={18}
              width={18}
              className="text-neutral-110 dark:text-storm-dust-100 __navigate-signin"
            />
            <p>Back to home</p>
          </Link>
        )}
      </div>
    </Layout>
  );
};

export default ErrorPage;
