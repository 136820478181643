import React from "react";

const NetworkIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3804 11.0666C15.0689 10.4274 15.4998 9.51441 15.4998 8.50079C15.4998 6.5678 13.9328 5.00079 11.9998 5.00079C10.0668 5.00079 8.49982 6.5678 8.49982 8.50079C8.49982 10.2701 9.81269 11.7328 11.5174 11.9678M14.3804 11.0666C13.7558 11.6463 12.9192 12.0008 11.9998 12.0008C11.8361 12.0008 11.6751 11.9896 11.5174 11.9678M14.3804 11.0666L21.5763 18.4959M11.5174 11.9678L8.87246 21.2441M8.87246 21.2441C8.45099 21.0643 7.98703 20.9647 7.49982 20.9647C5.56682 20.9647 3.99982 22.5317 3.99982 24.4647C3.99982 26.3977 5.56682 27.9647 7.49982 27.9647C9.43281 27.9647 10.9998 26.3977 10.9998 24.4647C10.9998 23.9765 10.8999 23.5117 10.7193 23.0895M8.87246 21.2441C9.70087 21.5977 10.3652 22.2615 10.7193 23.0895M10.7193 23.0895L20.9702 20.5174M21.5763 18.4959C21.1936 19.057 20.9698 19.7352 20.9698 20.4656C20.9698 20.4829 20.97 20.5002 20.9702 20.5174M21.5763 18.4959C22.2064 17.5721 23.2673 16.9656 24.4698 16.9656C26.4028 16.9656 27.9698 18.5326 27.9698 20.4656C27.9698 22.3986 26.4028 23.9656 24.4698 23.9656C22.5541 23.9656 20.9979 22.4265 20.9702 20.5174"
      stroke="currentColor"
      strokeWidth="2.5"
    />
  </svg>
);

export default NetworkIcon;
