import React from "react";

const AppServiceIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.99935 15.3786V15.3786C3.1584 15.3786 1.66602 13.8862 1.66602 12.0452V11.8192C1.66602 9.85342 3.2596 8.25983 5.22539 8.25983H6.11996L6.27506 7.41335C6.66794 5.26923 8.53653 3.71191 10.7163 3.71191V3.71191C12.8962 3.71191 14.7648 5.26923 15.1576 7.41335L15.3127 8.25983H15.4128V8.25983C17.0254 8.25983 18.3327 9.56713 18.3327 11.1798V11.6286C18.3327 13.2394 17.0268 14.5452 15.416 14.5452V14.5452M11.9199 14.844H13.5689C13.845 14.844 14.0689 15.0679 14.0689 15.344V16.993C14.0689 17.2691 13.845 17.493 13.5689 17.493H11.9199C11.6438 17.493 11.4199 17.2691 11.4199 16.993V15.344C11.4199 15.0679 11.6438 14.844 11.9199 14.844ZM10.2539 9.88745H11.9029C12.179 9.88745 12.4029 10.1113 12.4029 10.3875V12.0364C12.4029 12.3126 12.179 12.5364 11.9029 12.5364H10.2539C9.97776 12.5364 9.75391 12.3126 9.75391 12.0364V10.3875C9.75391 10.1113 9.97776 9.88745 10.2539 9.88745ZM6.97266 14.0542H8.62163C8.89778 14.0542 9.12163 14.2781 9.12163 14.5542V16.2032C9.12163 16.4793 8.89777 16.7032 8.62163 16.7032H6.97266C6.69651 16.7032 6.47266 16.4793 6.47266 16.2032V14.5542C6.47266 14.2781 6.69651 14.0542 6.97266 14.0542Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
);

export default AppServiceIcon;
