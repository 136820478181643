import ConsoleIcon from "../../assets/svg/ConsoleIcon";
import LearnIcon from "../../assets/svg/LearnIcon";
import StoreIcon from "../../assets/svg/StoreIcon";
import { LC_BASE_URL, STORE_BASE_URL } from "../../config/envConstants";
import { jsx } from "@emotion/react";
import DocsIcon from "../../assets/svg/DocsIcon";
import EventsIcon from "../../assets/svg/EventsIcon";
import ForumsIcon from "../../assets/svg/ForumsIcon";
import WebinarIcon from "../../assets/svg/WebinarIcon";
import HomeIcon from "../../assets/svg/HomeIcon";
import ProfileIcon from "../../assets/svg/ProfileIcon";
import SettingsIcon from "../../assets/svg/SettingsIcon";
import HelpSupportIcon from "../../assets/svg/HelpSupportIcon";
import LogoutIcon from "../../assets/svg/LogoutIcon";
import EditIcon from "../../assets/svg/EditIcon";
import KeyIcon from "../../assets/svg/KeyIcon";
import AccountSettingsSvg from "../../assets/svg/AccountSettingsIcon";
import MechaMachineIcon from "../../assets/svg/MechaMachineIcon";
import NetworkIcon from "../../assets/svg/NetworkIcon";
import GrafanaIcon from "../../assets/svg/GrafanaIcon";
import AppServiceIcon from "../../assets/svg/AppServiceIcon";

export const machineNavbarData = [
  {
    id: 0,
    title: "mecha",
    link: "/",
    linkTextColor: undefined,
    logo: "MechaLogo",
    iconPosition: "left",
    navigationComponents: [
      {
        __component: "navigation.dropdown",
        id: 0,
        list: [
          {
            id: 0,
            title: "Platform",
            link: "#",
          },
          {
            id: 1,
            title: "Hardware",
            link: "#",
          },
          {
            id: 2,
            title: "Solutions",
            link: "#",
          },
          {
            id: 3,
            title: "Store",
            link: STORE_BASE_URL,
          },
          {
            id: 4,
            title: "App Center",
            link: "/",
          },
          {
            id: 5,
            title: "Resources",
            link: "#",
            navigationComponents: [
              {
                __component: "navigation.dropdown",
                id: 0,
                list: [
                  {
                    id: 0,
                    title: "Learning center",
                    link: LC_BASE_URL + "/learn",
                    icon: jsx(LearnIcon),
                    iconPosition: "left",
                    tagLine: "Get to know more about Mecha",
                  },
                  {
                    id: 1,
                    title: "Forums",
                    link: "#",
                    icon: jsx(ForumsIcon),
                    iconPosition: "left",
                    tagLine: "Ask questions, build your community",
                  },
                  {
                    id: 2,
                    title: "Docs",
                    link: "#",
                    icon: jsx(DocsIcon),
                    iconPosition: "left",
                    tagLine: "Readable material on Mecha",
                  },
                  {
                    id: 3,
                    title: "Beta Program",
                    link: STORE_BASE_URL,
                    icon: jsx(StoreIcon),
                    iconPosition: "left",
                    tagLine: "Buy Mecha compute",
                  },
                  {
                    id: 4,
                    title: "Webinars",
                    link: "#",
                    icon: jsx(WebinarIcon),
                    iconPosition: "left",
                    tagLine: "Buy Mecha compute",
                  },
                  {
                    id: 5,
                    title: "Events",
                    link: LC_BASE_URL + "/events",
                    icon: jsx(EventsIcon),
                    iconPosition: "left",
                    tagLine: "Buy Mecha compute",
                  },
                  {
                    id: 6,
                    title: "Go to mecha.so",
                    link: "/",
                    icon: jsx(HomeIcon),
                    iconPosition: "left",
                    tagLine: "",
                  },
                ],
              },
            ],
          },
          {
            id: 6,
            title: "Blog",
            link: "#",
          },
        ],
      },
      {
        __component: "navigation.predefined",
        id: 1,
        list: ["github", "console_login"],
      },
    ],
  },
];

export const machineLoggedInNavbarData = [
  {
    id: 0,
    title: "Console",
    link: "/",
    linkTextColor: "",
    logo: "MechaIcon",
    icon: jsx(ConsoleIcon),
    iconPosition: "left",
    navigationComponents: [
      {
        __component: "navigation.dropdown",
        id: 0,
        list: [
          // {
          //   id: 0,
          //   title: "Machines",
          //   link: "/machines",
          // },
          // {
          //   id: 1,
          //   title: "Automation",
          //   link: "/automation",
          // },
          // {
          //   id: 1,
          //   title: "Apps",
          //   link: "/apps",
          // },
          {
            id: 0,
            title: "Machines",
            link: "/machines",
            icon: jsx(MechaMachineIcon, {
              className:
                "w-[18px] h-[18px] cursor-pointer flex items-end ml-1 md:group-hover:dark:text-white md:group-hover:text-neutral-150",
            }),
            iconPosition: "left",
          },
          // {
          //   id: 1,
          //   title: "Telemetry",
          //   link: "/#",
          //   icon: jsx(TelementryIcon, {
          //     className:
          //       "w-[18px] h-[18px] cursor-pointer flex items-end text-custom-310 dark:text-neutral-360 ml-1",
          //   }),
          //   iconPosition: "left",
          // },
          {
            id: 1,
            title: "Networking",
            link: "/networking",
            icon: jsx(NetworkIcon, {
              className:
                "w-[18px] h-[18px] cursor-pointer flex items-end ml-1 md:group-hover:dark:text-white md:group-hover:text-neutral-150",
            }),
            iconPosition: "left",
          },
          {
            id: 2,
            title: "App Services",
            link: "/app-services",
            icon: jsx(AppServiceIcon, {
              className:
                "w-[18px] h-[18px] cursor-pointer flex items-end ml-1 md:group-hover:dark:text-white md:group-hover:text-neutral-150",
            }),
            iconPosition: "left",
          },
          {
            id: 3,
            title: "Settings",
            link: "/settings/my-account",
            icon: jsx(SettingsIcon, {
              className:
                "w-[18px] h-[18px] cursor-pointer flex items-end ml-1 md:group-hover:dark:text-white md:group-hover:text-neutral-150",
            }),
            iconPosition: "left",
          },
          // h-max px-2 py-2 rounded-md md:hover:dark:bg-mecha-gray-140 md:hover:bg-custom-1050 md:hover:dark:text-white hover:text-neutral-150
          // {
          //   id: 2,
          //   title: "Docs",
          //   link: "docs",
          // },
          // {
          //   id: 3,
          //   title: "Community",
          //   link: "community",
          // },
          // {
          //   id: 3,
          //   title: "Resources",
          //   link: "resources",
          //   navigationComponents: [
          //     {
          //       __component: "navigation.dropdown",
          //       id: 0,
          //       list: [
          //         {
          //           id: 0,
          //           title: "Learning center",
          //           link: LC_BASE_URL + "/learn",
          //           icon: jsx(LearnIcon),
          //           iconPosition: "left",
          //           tagLine: "Get to know more about Mecha",
          //         },
          //         {
          //           id: 1,
          //           title: "Forums",
          //           link: "#",
          //           icon: jsx(ForumsIcon),
          //           iconPosition: "left",
          //           tagLine: "Ask questions, build your community",
          //         },
          //         {
          //           id: 2,
          //           title: "Docs",
          //           link: "#",
          //           icon: jsx(DocsIcon),
          //           iconPosition: "left",
          //           tagLine: "Readable material on Mecha",
          //         },
          //         {
          //           id: 3,
          //           title: "Beta Program",
          //           link: STORE_BASE_URL,
          //           icon: jsx(StoreIcon),
          //           iconPosition: "left",
          //           tagLine: "Buy Mecha compute",
          //         },
          //         {
          //           id: 4,
          //           title: "Webinars",
          //           link: "#",
          //           icon: jsx(WebinarIcon),
          //           iconPosition: "left",
          //           tagLine: "Buy Mecha compute",
          //         },
          //         {
          //           id: 5,
          //           title: "Events",
          //           link: LC_BASE_URL + "/events",
          //           icon: jsx(EventsIcon),
          //           iconPosition: "left",
          //           tagLine: "Buy Mecha compute",
          //         },
          //         {
          //           id: 6,
          //           title: "Go to mecha.so",
          //           link: "/",
          //           icon: jsx(HomeIcon),
          //           iconPosition: "left",
          //           tagLine: "",
          //         },
          //       ],
          //     },
          //   ],
          // },
          // {
          //   id: 4,
          //   title: "Settings",
          //   link: "/settings",
          // },
        ],
      },
      {
        __component: "navigation.predefined",
        id: 1,
        list: ["docs", "forum", "user_profile"],
        // list: ["user_profile"],
      },
    ],
  },
];

export const notificationData = [
  { id: 0, title: "Notification 1", link: "#" },
  { id: 1, title: "Notification 2", link: "#" },
  { id: 2, title: "Notification 3", link: "#" },
  { id: 3, title: "Notification 4", link: "#" },
  { id: 4, title: "Notification 5", link: "#" },
  { id: 5, title: "Notification 6", link: "#" },
];

export const profileData = [
  [
    {
      id: 0,
      title: "launch_grafana",
      link: "#",
      icon: jsx(GrafanaIcon, {
        className: "w-[20px] h-[20px]",
      }),
    },
    { id: 1, title: "Account", link: "/settings/my-account" },
    { id: 2, title: "Profile", link: "/settings/my-profile" },
    // { id: 1, title: "Edit Profile", link: "/settings/my-profile" },
    { id: 3, title: "API Keys", link: "/settings/api-keys" },
  ],
  [{ id: 4, title: "Sign out", link: "/auth/signin" }],
];

export const mobileProfileData = [
  [
    {
      id: 0,
      title: "View Profile",
      link: "/profile",
      icon: jsx(ProfileIcon, { className: "w-4 h-4" }),
    },
    {
      id: 1,
      title: "Account",
      link: "/settings/my-account",
      icon: jsx(AccountSettingsSvg, { className: "w-5 h-5" }),
    },
    {
      id: 2,
      title: "Edit Profile",
      link: "/settings/my-profile",
      icon: jsx(EditIcon, { className: "w-4 h-4" }),
    },
  ],
  [
    {
      id: 3,
      title: "Settings",
      link: "/settings/my-profile",
      icon: jsx(SettingsIcon, { className: "w-5 h-5" }),
    },
    {
      id: 4,
      title: "Help",
      link: "/help-&-support",
      icon: jsx(HelpSupportIcon, { className: "w-5 h-5" }),
    },
  ],
  [
    {
      id: 5,
      title: "Sign out",
      link: "/auth/signin",
      icon: jsx(LogoutIcon, { className: "w-4 h-4" }),
    },
  ],
];

export const navigationListErrorPage = [
  {
    id: 0,
    title: "",
    link: "/",
    heroLink: false,
    logo: "MechaLogo",
    navigationComponents: [
      {
        __component: "navigation.predefined",
        id: 1,
        list: ["search"],
      },
    ],
  },
];

export const settingNavList = [
  {
    name: "Account",
    slug: "my-account",
    icon: jsx(SettingsIcon, {
      className: "w-5 h-5 mr-1.5",
      strokeWidth: 3,
    }),
  },
  {
    name: "Profile",
    slug: "my-profile",
    icon: jsx(ProfileIcon, {
      className: "w-5 h-5 mr-1.5",
    }),
  },
  {
    name: "API Keys",
    slug: "api-keys",
    icon: jsx(KeyIcon, {
      className: "w-5 h-5 mr-1.5",
      strokeWidth: 3,
    }),
  },
  // {
  //   name: "Recovery Key",
  //   slug: "recovery-key",
  //   icon: jsx(RecoveryKey, {
  //     className: "w-5 h-5 mr-1.5",
  //     strokeWidth: 3,
  //   }),
  // },
  // {
  //   name: "Connections",
  //   slug: "connections",
  //   icon: jsx(ConnectionIcon, {
  //     className: "w-5 h-5 mr-1.5",
  //     strokeWidth: 3,
  //   }),
  // },
  // {
  //   name: "Themes",
  //   slug: "themes",
  //   icon: jsx(ThemeIcon, {
  //     className: "w-5 h-5 mr-1.5",
  //     strokeWidth: 3,
  //   }),
  // },
  // {
  //   name: "Appearance",
  //   slug: "appearance",
  //   icon: jsx(AppearanceIcon, {
  //     className: "w-5 h-5 mr-1.5",
  //     strokeWidth: 3,
  //   }),
  // },
  // {
  //   name: "Help & Support",
  //   slug: "help-&-support",
  //   icon: jsx(HelpSupportIcon, {
  //     className: "w-5 h-5 mr-1.5",
  //     strokeWidth: 3,
  //   }),
  // },
];
