import React from "react";

const EditIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.2929 4L14.2929 7M1.00003 17.2929L2.00003 13.2929L12.8787 2.41421C13.6598 1.63317 14.9261 1.63316 15.7071 2.41421L15.8787 2.58579C16.6598 3.36683 16.6598 4.63316 15.8787 5.41421L5.00003 16.2929L1.00003 17.2929Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
