import React from "react";

const LearnIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 5.15957L2.65573 1.61588C2.95511 0.975105 3.86632 0.975106 4.1657 1.61588L5.82143 5.15957M1 5.15957H5.82143M1 5.15957V12.6984V13.5893C1 14.9207 2.07931 16 3.41071 16V16C4.74211 16 5.82143 14.9207 5.82143 13.5893V12.6984V5.15957M9.57143 7.85163V3.36232C9.57143 2.90209 9.94452 2.52899 10.4048 2.52899H15.1667C15.6269 2.52899 16 2.90208 16 3.36232V15.1667C16 15.6269 15.6269 16 15.1667 16H10.4048C9.94452 16 9.57143 15.6269 9.57143 15.1667V12.1905M9.57143 7.85163H11.7143M9.57143 7.85163V12.1905M9.57143 12.1905H12.7143"
      stroke="#FEDD00"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default LearnIcon;
