import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Loader from "../../assets/svg/LoaderIcon";
import { useStore } from "../../storeManager/stores";
import { observer } from "mobx-react";
import { PageTitles } from "../../config/constants";
import { isSessionPrivileged } from "../../utils/validator";
import ErrorBlock from "../ErrorBlock";

const authRoute = (Component: any, title?: string, redirect = true) => {
  const AuthenticationRoute = (props: any) => {
    const { isUserPresent, isLoading, loggedInUser, apiError } =
      useStore().authStore;
    const router = useRouter();
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
      if (!apiError.show && !isLoading && !isUserPresent && redirect) {
        router.push("/auth/signin");
        return;
      }
      if (
        !apiError.show &&
        loggedInUser.sessionStartAt &&
        title == PageTitles.CONFIRM_ACCESS &&
        isSessionPrivileged(loggedInUser.sessionStartAt)
      ) {
        setShowLoading(true);
        router.push("/settings/my-account");
        return;
      }
      setShowLoading((isLoading || !isUserPresent) && redirect);
    }, [isUserPresent, isLoading, router, loggedInUser]);

    if (apiError.show) {
      console.log("Error in whoammi", apiError);

      return (
        <ErrorBlock
          statusCode={apiError.status_code}
          reason={""}
          redirectToauth={false}
        />
      );
    }
    if (showLoading) {
      return (
        <div className="dark:bg-dark h-screen flex justify-center items-center">
          <Loader className="w-10 h-10 text-primary-500 inline animate-spin" />
        </div>
      );
    }

    return (
      <>
        <Head>
          <title>{title}</title>
          <link
            rel="icon"
            href="/mecha_small_logo.svg"
            sizes="any"
            type="image/svg+xml"
          />
        </Head>
        <Component {...props} />
      </>
    );
  };

  return observer(AuthenticationRoute);
};
export default authRoute;
