import React from "react";

const MechaMachineIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="4.58344"
      y="0.833252"
      width="10.8333"
      height="17.9167"
      rx="1.68832"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <rect
      x="14.5"
      y="11.5"
      width="0.5"
      height="2"
      rx="0.25"
      transform="rotate(90 14.5 11.5)"
      fill="currentColor"
    />
    <path
      d="M16.1833 2.0835C16.4134 2.0835 16.6 2.27004 16.6 2.50016V3.3335C16.6 3.56362 16.4134 3.75016 16.1833 3.75016V2.0835Z"
      fill="currentColor"
    />
    <path
      d="M16.1833 4.5835C16.4134 4.5835 16.6 4.77004 16.6 5.00016V5.41683C16.6 5.64695 16.4134 5.8335 16.1833 5.8335V4.5835Z"
      fill="currentColor"
    />
    <path
      d="M16.1833 7.0835C16.4134 7.0835 16.6 7.27004 16.6 7.50016V10.4168C16.6 10.6469 16.4134 10.8335 16.1833 10.8335V7.0835Z"
      fill="currentColor"
    />
    <line x1="15" y1="10.75" x2="5" y2="10.75" stroke="currentColor" />
    <line x1="15" y1="12.75" x2="5" y2="12.75" stroke="currentColor" />
  </svg>
);

export default MechaMachineIcon;
