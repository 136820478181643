import React, { useEffect } from "react";
import ErrorPage from "../shared/ErrorPageComponent";
import Head from "next/head";

interface ErrorBlockProps {
  statusCode: number;
  reason: string;
  redirectToauth: boolean;
}

function ErrorBlock(props: ErrorBlockProps) {
  const { statusCode, reason, redirectToauth } = props;
  const PageNotFoundText =
    "We can't find the page you were looking for.\nPlease check the URL and try again";

  const InternalServerErrorText =
    "Sorry there were some server issues while processing your request";

  const ErrorLogInText =
    "Your login could not be completed. Please check the reason below";

  const ErrorProcessingText =
    "Your request could not be processed. Please check the reason below";

  return (
    <>
      <Head>
        <title>{(statusCode || "Error") + " - " + "Mecha"}</title>
      </Head>
      <div className="flex font-space-grotesk flex-col dark:bg-dark bg-stormdust-100 xs:min-h-screen lg:h-screen w-full">
        {/* content and illustration */}
        {statusCode === 404 ? (
          <ErrorPage
            title={"404: Page not found"}
            error={PageNotFoundText}
            redirectToauth={redirectToauth}
          />
        ) : statusCode === 401 ? (
          <ErrorPage
            title={"401: Error Logging in"}
            error={ErrorLogInText}
            description={
              reason || "Credentials unmatched error. Try logging in again"
            }
            redirectToauth={redirectToauth}
          />
        ) : statusCode === 400 ? (
          <ErrorPage
            title={"400: Error Processing request"}
            error={ErrorProcessingText}
            description={
              reason ||
              "Unknown error has occurred, please try again. Or reach out to us for help."
            }
            redirectToauth={redirectToauth}
          />
        ) : (
          <ErrorPage
            title={"500: Internal Server Error"}
            error={InternalServerErrorText}
            redirectToauth={redirectToauth}
          />
        )}
      </div>
    </>
  );
}

// tslint:disable-next-line: no-any
ErrorBlock.getInitialProps = ({ res, err }: any) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default ErrorBlock;
