import React from "react";

const DocsIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.97705 17.334L9.48998 17.334C9.93414 17.334 10.3599 17.1567 10.6728 16.8415L12.4253 15.076L14.1829 13.3053C14.4928 12.9931 14.6667 12.5711 14.6667 12.1312L14.6667 11.9816M8.97705 17.334L6.33337 17.334C5.4129 17.334 4.66671 16.5878 4.66671 15.6673L4.66671 6.50065C4.66671 5.58018 5.4129 4.83398 6.33337 4.83398L10.8736 4.83398L13 4.83398C13.9205 4.83398 14.6667 5.58018 14.6667 6.50065L14.6667 11.0548L14.6667 11.9816M8.97705 17.334L8.97705 13.6483C8.97705 12.7278 9.72324 11.9816 10.6437 11.9816L14.6667 11.9816M3.97705 14.0007L3.00004 14.0007C2.07957 14.0007 1.33337 13.2545 1.33337 12.334L1.33337 3.16732C1.33337 2.24684 2.07957 1.50065 3.00004 1.50065L7.54027 1.50065L9.66671 1.50065C10.5872 1.50065 11.3334 2.24684 11.3334 3.16732L11.3334 4.3881"
      stroke="#C49BFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocsIcon;
