import React from "react";

const ProfileIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33301 15C3.33301 12.9289 5.01194 11.25 7.08301 11.25H12.9163C14.9874 11.25 16.6663 12.9289 16.6663 15V15C16.6663 15.9205 15.9201 16.6667 14.9997 16.6667H4.99967C4.0792 16.6667 3.33301 15.9205 3.33301 15V15Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinejoin="round"
    />
    <circle
      cx="10"
      cy="5.83325"
      r="2.5"
      stroke="currentColor"
      strokeWidth="1.66667"
    />
  </svg>
);

export default ProfileIcon;
