import React from "react";

const HelpSupportIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="9"
      cy="8.99994"
      r="7.5"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.82149 6.32146C8.14618 5.99677 8.5715 5.83405 8.99706 5.8333C9.42458 5.83255 9.85233 5.99527 10.1785 6.32146C10.5039 6.64689 10.6667 7.07343 10.6667 7.49997C10.6667 7.92651 10.5039 8.35304 10.1785 8.67848C9.85233 9.00466 9.42458 9.16738 8.99706 9.16663L9 9.99997"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="9"
      y="12.3333"
      width="0.00833333"
      height="0.00833333"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default HelpSupportIcon;
