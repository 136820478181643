import { MainHeader } from "@mecha/components/dist/src/components/Layout/MainHeader";
import { useRouter } from "next/router";
import { getLoginUrl } from "../../utils/services";
import { useStore } from "../../storeManager/stores";
import {
  machineLoggedInNavbarData,
  machineNavbarData,
  notificationData,
  profileData,
} from "./navbarConstants";
import { EventTracker } from "../../libs/EventTracker";
import { ErrorReporter } from "../../libs/ErrorReporter";
import { useTheme } from "next-themes";
import useToggleTheme from "../../utils/toogleTheme";
import { useEffect, useState } from "react";
import { cn } from "../../utils/tailwindHelper";
import { isWindowUndefined } from "../../utils/isWindowUndefined";
import { logout } from "../../libs/auth/actions";

const Navbar = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const handleScroll = () => {
    if (!isWindowUndefined(window)) {
      setScrollY(window.scrollY);
    }
  };
  const { theme } = useTheme();
  const toggleTheme = useToggleTheme();

  const router = useRouter();
  const { loggedInUser, isUserPresent } = useStore().authStore;
  const { asPath } = router;
  const onLogin = (url: string) => {
    const loginUrl = getLoginUrl(url);
    router.push(loginUrl);
  };
  const [urlTitle, setUrlTitle] = useState("");
  const signUp = () => {
    router.push("/auth/signup");
  };
  const onSignOut = async () => {
    try {
      EventTracker.resetUser();
      const RealTimeEvents = (await import("../../libs/RealTimeEvents"))
        .default;
      RealTimeEvents.disconnect();
      sessionStorage.clear();
      localStorage.clear();

      logout();
      // !isWindowUndefined(window) ? router.push("/auth/signin?logout=true") : "";
    } catch (error) {
      ErrorReporter.collect(error, "Error in signing out");

      //ORY signout api fails if there is no api found, we are reloading
      //page to call whoami and eventually redirect to signin page if there is
      //no session found
      router.reload();
    }
  };

  const extractCategoryFromUrl = () => {
    const pathSegments = router.pathname.split("/");
    const categoriesToExtract = ["networking", "machines", "settings", "app-services"];

    if (pathSegments.length > 1) {
      const category = categoriesToExtract.find(cat => cat === pathSegments[1]);
      if (category) {
        setUrlTitle(category);
        return category;
      }
    }
    setUrlTitle("");
    return "";
  };

  useEffect(() => {
    extractCategoryFromUrl();
  }, [router.pathname]);

  useEffect(() => {
    // Add event listener to track scroll
    !isWindowUndefined(window) &&
      window.addEventListener("scroll", handleScroll);

    if (document.body.classList.contains("env--disable-sticky-header")) {
      setIsFixed(false);
    } else {
      setIsFixed(true);
    }
    // Remove the event listener when the component unmounts
    return () => {
      !isWindowUndefined(window) &&
        window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (isUserPresent) {
    return (
      // <>
      // <div className="xs:hidden lg:block">
      <MainHeader
        topFixed={isFixed}
        menuList={machineLoggedInNavbarData}
        onLogin={() => onLogin(asPath)}
        onCreateAccount={signUp}
        onLogout={onSignOut}
        notificationData={notificationData}
        profileData={profileData}
        userData={loggedInUser}
        consoleLink={"/"}
        subActive={`/` + urlTitle?.toLowerCase()}
        theme={theme}
        setTheme={toggleTheme}
        wrapperClass={cn("dark:bg-[#15171D]", {
          " border-b dark:border-[#202431] dark:bg-[#15171D]": scrollY > 72,
        })}
      />
      // {/* </div> */}
      // {/* <div className="xs:block lg:hidden">
      //   <MainHeader
      //     menuList={machineLoggedInNavbarData}
      //     onLogin={() => onLogin(asPath)}
      //     onCreateAccount={signUp}
      //     onLogout={onSignOut}
      //     notificationData={notificationData}
      //     profileData={mobileProfileData}
      //     userData={loggedInUser}
      //     consoleLink={"/"}
      //     subActive={`/` + title?.toLowerCase()}
      //     theme={theme}
      //     setTheme={toggleTheme}
      //   />
      // </div> */}
      // </>
    );
  }

  return (
    <MainHeader
      topFixed={isFixed}
      menuList={machineNavbarData}
      shadow="0"
      onLogin={() => onLogin(asPath)}
      onCreateAccount={signUp}
      onLogout={onSignOut}
      notificationData={notificationData}
      profileData={profileData}
      subActive={`/` + urlTitle?.toLowerCase()}
      setTheme={toggleTheme}
      wrapperClass={cn("dark:bg-[#05070a]", {
        " border-b dark:border-[#202431] dark:bg-[090e13]": scrollY > 72,
      })}
    />
  );
};
export default Navbar;
