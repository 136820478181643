import React from "react";

const HomeIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="#0071EB"
    {...props}
  >
    <path
      d="M11.6667 5.66667V11C11.6667 12.1046 10.7712 13 9.66667 13H4.33333C3.22876 13 2.33333 12.1046 2.33333 11V5.66667M13 7L7 1L1 7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HomeIcon;
