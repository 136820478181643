import React from "react";

const EventsIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3333 11.666V14.9993M10.6667 13.3327H14M7.75 15.8327H3.16667C2.24619 15.8327 1.5 15.0865 1.5 14.166V2.49935H14.8333V8.74935M1.5 5.83268H14.8333M11.5 1.66602V3.33268M4.83333 1.66602V3.33268M17.3333 13.3327C17.3333 16.0941 15.0948 18.3327 12.3333 18.3327C9.57191 18.3327 7.33333 16.0941 7.33333 13.3327C7.33333 10.5713 9.57191 8.33268 12.3333 8.33268C15.0948 8.33268 17.3333 10.5713 17.3333 13.3327Z"
      stroke="#FAEC78"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EventsIcon;
