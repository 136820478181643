import React from "react";

const WebinarIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.16654 9.41667H5.24988C3.17881 9.41667 1.49988 11.0956 1.49988 13.1667C1.49988 14.0871 2.24607 14.8333 3.16654 14.8333H7.33321M17.3333 12.3334C17.3333 15.0948 15.0947 17.3334 12.3333 17.3334C9.57189 17.3334 7.33331 15.0948 7.33331 12.3334C7.33331 9.57193 9.57189 7.33336 12.3333 7.33336C15.0947 7.33336 17.3333 9.57193 17.3333 12.3334ZM10.6665 4C10.6665 5.38071 9.54726 6.5 8.16654 6.5C6.78583 6.5 5.66654 5.38071 5.66654 4C5.66654 2.61929 6.78583 1.5 8.16654 1.5C9.54726 1.5 10.6665 2.61929 10.6665 4Z"
      stroke="#FF8F1C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4167 12.3327L11.5 13.9993V10.666L14.4167 12.3327Z"
      fill="#FF8F1C"
      stroke="#FF8F1C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WebinarIcon;
