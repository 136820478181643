import React from "react";

const ForumsIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.66671 3.16667C6.66671 4.08714 5.92052 4.83333 5.00004 4.83333C4.07957 4.83333 3.33337 4.08714 3.33337 3.16667C3.33337 2.24619 4.07957 1.5 5.00004 1.5C5.92052 1.5 6.66671 2.24619 6.66671 3.16667Z"
      stroke="#FF6D6A"
      strokeWidth="1.5"
    />
    <path
      d="M9.16687 15.4742C9.16687 14.1997 10.2001 13.1665 11.4746 13.1665H16.8592C18.1337 13.1665 19.1669 14.1997 19.1669 15.4742C19.1669 16.0406 18.7077 16.4998 18.1412 16.4998H10.1925C9.62607 16.4998 9.16687 16.0406 9.16687 15.4742Z"
      stroke="#FF6D6A"
      strokeWidth="1.5"
    />
    <path
      d="M0.833374 9.64119C0.833374 8.36669 1.86656 7.3335 3.14107 7.3335H6.85901C8.13352 7.3335 9.16671 8.36669 9.16671 9.64119C9.16671 10.2076 8.70751 10.6668 8.14107 10.6668H1.85901C1.29257 10.6668 0.833374 10.2076 0.833374 9.64119Z"
      stroke="#FF6D6A"
      strokeWidth="1.5"
    />
    <path
      d="M15.8335 9.00016C15.8335 9.92064 15.0873 10.6668 14.1668 10.6668C13.2463 10.6668 12.5001 9.92064 12.5001 9.00016C12.5001 8.07969 13.2463 7.3335 14.1668 7.3335C15.0873 7.3335 15.8335 8.07969 15.8335 9.00016Z"
      stroke="#FF6D6A"
      strokeWidth="1.5"
    />
  </svg>
);

export default ForumsIcon;
